<template>
    <section id="portfolio" class="portfolio-area portfolio-four pb-100" dir="ltr" >

      <!-- <vue-masonry-gallery :imgsArr="arrayImg" ></vue-masonry-gallery> -->

        <!-- <button @click="preview">Preview</button> -->

            <!--====== PROJECT PART START ======-->

    <section id="project" class="project-area pb-30 pt-10">

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="section-title text-center pb-10">
                         <h3 class="title"> {{ $t('Portfolio')}}</h3>
                         <div class="c-line"></div>
                    </div> <!-- section title -->
                </div>
            </div> <!-- row -->
        </div>

        <!-- <div class="container"> -->
            <div class="row project-box">

          

                <div class="project-item"   v-for="(groupImage, index) in imageReviews" :key="index" v-show="index >= firstShowing && index < lastShowing " >
                    <swiper class="swiper" 
                        :options="swiperOption" 
                        ref="swiper"
                        @click.native="slideClicked(groupImage, $event)"
                        
                        >
                       
                        <swiper-slide   v-for="(item, index) in groupImage" :key="index" v-bind-data-index="index">
                            <div class="single-project">
                                <div class="project-image">
                                    <img :src="item.path" alt="Project" class="project-img">
<!--                                  <v-lazy-image :src="item.path" alt="Project" class="project-img" />-->
                                </div>
                                <div class="project-content">
                                    <p style="cursor:pointer" class="project-title project-title-new-style" > {{item.type}} </p>
                                    <!-- <a class="project-title"  href="#" @click="slideClicked(index,'amro')"> <i class="lni lni-zoom-in"></i>{{item.type}} </a> -->
                                    <!-- <a class="project-title" href="#">Home Interior Design</a> -->
                                </div>
                            </div>
                        </swiper-slide>
                        
                        <div class="swiper-pagination " slot="pagination"></div>
                    </swiper>
                </div>
 
                <!-- pagination -->
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="section-title text-center">
                                <div class="pagination">
                                    <!-- <a href="#"  @click="changePreviewTap(0,3)" >&laquo;</a> -->
                                    <a   @click="changePreviewTap(0,6)"  :class = "(firstShowing == 0 && lastShowing == 6) ? 'active': '' "   > 1</a>
                                    <a   @click="changePreviewTap(6,12)" :class = "(firstShowing == 6 && lastShowing == 12) ? 'active': '' "  >2</a>
                                    <a   @click="changePreviewTap(12, 17)" :class = "(firstShowing == 12 && lastShowing == 17) ? 'active': '' "  >3</a>
                                    <!-- <a href="#">&raquo;</a> -->
                                </div>
                            </div> <!-- section title -->
                        </div>
                    </div> <!-- row -->
                </div>

                    
                <!-- End pagination -->

                <!-- <button @click="changePreviewTap(0,3)"> change </button> -->
                <!-- < -->
            </div>
        <!-- </div> -->
    </section>

    <!--====== PROJECT PART ENDS ======-->
    
        <!-- </div> -->
        <!-- container -->
    </section>
</template>

<script>
// import VueMasonryGallery from "vue-masonry-gallery";

// main.js
import 'image-preview-vue/lib/imagepreviewvue.css'
// other.js
import imagePreview from 'image-preview-vue'
// import VLazyImage from "v-lazy-image/v2";

// import VuePictureSwipe from 'vue-picture-swipe';

export default {
  components: {
    // imagePreview
    // VuePictureSwipe
    // VLazyImage
  },
    data() {
        return {
            firstShowing: 0,
            lastShowing: 6,
          arrayImg:[
            'assets/images/portfolio/bok/1.jpeg',
            'assets/images/portfolio/bok/1.jpeg',
            'assets/images/portfolio/bok/1.jpeg',
          ],
          previewConfig:
            {
            "initIndex": 0,
            "isEnableBlurBackground": false,
            "isEnableLoopToggle": true,
            "initViewMode": "contain",
            "containScale": 1,
            "shirnkAndEnlargeDeltaRatio": 0.2,
            "wheelScrollDeltaRatio": 0.2,
            "isEnableImagePageIndicator": true,
            "maskBackgroundColor": "rgba(0,0,0,0.6)",
            "zIndex":4000,
            "isEnableDownloadImage": false
            },
           swiperOption: {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 2,
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1024: {
                    slidesPerView: 1,
                    spaceBetween: 40
                    },
                    768: {
                    slidesPerView: 1,
                    spaceBetween: 10
                    },
                    640: {
                    slidesPerView: 1,
                    spaceBetween: 10
                    },
                    320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                    }
                }
            },
          imageReviews : [

                // ai-condinal
                [
                    {
                        path : 'assets/images/portfolio/ai-condinal/2.webp',
                        type: 'Private HQ',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/ai-condinal/1.webp',
                        type: 'Private HQ',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/ai-condinal/3.webp',
                        type: 'Private HQ',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/ai-condinal/4.webp',
                        type: 'Private HQ',
                        height: '503px'
                    },
                    // {
                    //     path : 'assets/images/portfolio/ai-condinal/5.webp',
                    //     type: 'Private HQ',
                    //     height: '503px'
                    // },
                    {
                        path : 'assets/images/portfolio/ai-condinal/6.webp',
                        type: 'Private HQ',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/ai-condinal/7.webp',
                        type: 'Private HQ',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/ai-condinal/8.webp',
                        type: 'Private HQ',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/ai-condinal/9.webp',
                        type: 'Private HQ',
                        height: '503px'
                    },
                ],

                //alfaih-tower-main-reception
                [
                    {
                        path : 'assets/images/portfolio/alfaih-tower-main-reception/3.webp',
                        type: 'Alfaih Tower Main Reception',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/alfaih-tower-main-reception/2.webp',
                        type: 'Alfaih Tower Main Reception',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/alfaih-tower-main-reception/1.webp',
                        type: 'Alfaih Tower Main Reception',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/alfaih-tower-main-reception/4.webp',
                        type: 'Alfaih Tower Main Reception',
                        height: '503px'
                    },
                    // {
                    //     path : 'assets/images/portfolio/alfaih-tower-main-reception/5.webp',
                    //     type: 'Alfaih Tower Main Reception',
                    //     height: '503px'
                    // },
                ],
                
                // villa
                [
                    {
                        path : 'assets/images/portfolio/villa/3.webp',
                        type: 'Villa',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/villa/2.webp',
                        type: 'Villa',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/villa/1.webp',
                        type: 'Villa',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/villa/4.webp',
                        type: 'Villa',
                        height: '503px'
                    },
                ],  
                 //terma-medical-tower
                [
                    {
                        path : 'assets/images/portfolio/terma-medical-tower/3.webp',
                        type: 'Terma Medical Tower',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/terma-medical-tower/5.webp',
                        type: 'Terma Medical Tower',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/terma-medical-tower/2.webp',
                        type: 'Terma Medical Tower',
                        height: '503px'
                    },
                    // {
                    //     path : 'assets/images/portfolio/terma-medical-tower/4.webp',
                    //     type: 'Terma Medical Tower',
                    //     height: '503px'
                    // },
                    {
                        path : 'assets/images/portfolio/terma-medical-tower/1.webp',
                        type: 'Terma Medical Tower',
                        height: '503px'
                    },
                    
                ],
                // alwaha-bok-hq2
                [
                    {
                        path : 'assets/images/portfolio/alwaha-bok-hq2/4.webp',
                        type: 'Alwaha Bok HQ2',
                        height: '503px'
                    },
                    // {
                    //     path : 'assets/images/portfolio/alwaha-bok-hq2/2.webp',
                    //     type: 'Alwaha Bok HQ2',
                    //     height: '503px'
                    // },
                    {
                        path : 'assets/images/portfolio/alwaha-bok-hq2/3.webp',
                        type: 'Alwaha Bok HQ2',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/alwaha-bok-hq2/5.webp',
                        type: 'Alwaha Bok HQ2',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/alwaha-bok-hq2/1.webp',
                        type: 'Alwaha Bok HQ2',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/alwaha-bok-hq2/6.webp',
                        type: 'Alwaha Bok HQ2',
                        height: '503px'
                    },
                ],
                // corporate-fib
                [
                    {
                        path : 'assets/images/portfolio/corporate-fib/1.webp',
                        type: 'Corporate FIB',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/corporate-fib/2.webp',
                        type: 'Corporate FIB',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/corporate-fib/3.webp',
                        type: 'Corporate FIB',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/corporate-fib/4.webp',
                        type: 'Corporate FIB',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/corporate-fib/5.webp',
                        type: 'Corporate FIB',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/corporate-fib/6.webp',
                        type: 'Corporate FIB',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/corporate-fib/7.webp',
                        type: 'Corporate FIB',
                        height: '503px'
                    },
                ],
                
                //  -----------2----------
                
                // Go
                [
                    {
                        path : 'assets/images/portfolio/go/3.webp',
                        type: 'Go',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/go/2.webp',
                        type: 'Go',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/go/1.webp',
                        type: 'Go',
                        height: '503px'
                    },
                ],

                // bok-alobied
                [
                    {
                        path : 'assets/images/portfolio/bok-alobied/1.webp',
                        type: 'BOK Alobied',
                        height: '503px'
                    },
                    // {
                    //     path : 'assets/images/portfolio/bok-alobied/2.webp',
                    //     type: 'BOK Alobied',
                    //     height: '503px'
                    // },
                    {
                        path : 'assets/images/portfolio/bok-alobied/3.webp',
                        type: 'BOK Alobied',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/bok-alobied/4.webp',
                        type: 'BOK Alobied',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/bok-alobied/5.webp',
                        type: 'BOK Alobied',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/bok-alobied/6.webp',
                        type: 'BOK Alobied',
                        height: '503px'
                    },
                ],
                
                //mahgoub-sons-engineering
                [
                    {
                        path : 'assets/images/portfolio/mahgoub-sons-engineering/1.webp',
                        type: 'Mahgoub Sons Engineering',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/mahgoub-sons-engineering/2.webp',
                        type: 'Mahgoub Sons Engineering',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/mahgoub-sons-engineering/3.webp',
                        type: 'Mahgoub Sons Engineering',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/mahgoub-sons-engineering/4.webp',
                        type: 'Mahgoub Sons Engineering',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/mahgoub-sons-engineering/5.webp',
                        type: 'Mahgoub Sons Engineering',
                        height: '503px'
                    },
                ],
                // alrawabi-factory
                [
                    {
                      path : 'assets/images/portfolio/alrawabi-factory/1.webp',
                      type: 'Alrawabi Factory',
                      height: '503px'
                    },
                    {
                      path : 'assets/images/portfolio/alrawabi-factory/2.webp',
                      type: 'Alrawabi Factory',
                      height: '503px'
                    },
                    {
                      path : 'assets/images/portfolio/alrawabi-factory/5.webp',
                      type: 'Alrawabi Factory',
                      height: '503px'
                    },
                  {
                    path : 'assets/images/portfolio/alrawabi-factory/4.webp',
                    type: 'Alrawabi Factory',
                    height: '503px'
                  },
                    {
                        path : 'assets/images/portfolio/alrawabi-factory/3.webp',
                        type: 'Alrawabi Factory',
                        height: '503px'
                    },




                    {
                        path : 'assets/images/portfolio/alrawabi-factory/6.webp',
                        type: 'Alrawabi Factory',
                        height: '503px'
                    },
                ],
                // sharjah-hall-khartoum-university
                [
                    {
                        path : 'assets/images/portfolio/sharjah-hall-khartoum-university/1.webp',
                        type: 'Sharjah HAll - Khartoum University',
                        height: '503px'
                    },
                ],

                
                // kanana-bok
                [
                    {
                      path : 'assets/images/portfolio/kanana-bok/6.webp',
                      type: 'BOK - kenana by Container',
                      height: '503px'
                    },
                    {
                      path : 'assets/images/portfolio/kanana-bok/4.webp',
                      type: 'BOK - kenana by Container',
                      height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/kanana-bok/2.webp',
                        type: 'BOK - kenana by Container',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/kanana-bok/1.webp',
                        type: 'BOK - kenana by Container',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/kanana-bok/3.webp',
                        type: 'BOK - kenana by Container',
                        height: '503px'
                    },

                    // {
                    //     path : 'assets/images/portfolio/kanana-bok/5.webp',
                    //     type: 'BOK - kenana by Container',
                    //     height: '503px'
                    // },

                ],

                // -------  3-----------

                
                //rida-guinea-project
                [
                    {
                        path : 'assets/images/portfolio/rida-guinea-project/1.webp',
                        type: 'Rida Guinea Project',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/rida-guinea-project/2.webp',
                        type: 'Rida Guinea Project',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/rida-guinea-project/3.webp',
                        type: 'Rida Guinea Project',
                        height: '503px'
                    },
                    
                ],
                
                 

                 // bok-bahri
                 [
                    {
                        path : 'assets/images/portfolio/bok-bahri/1.webp',
                        type: 'Bok Bahri',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/bok-bahri/2.webp',
                        type: 'Bok Bahri',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/bok-bahri/3.webp',
                        type: 'Bok Bahri',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/bok-bahri/4.webp',
                        type: 'Bok Bahri',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/bok-bahri/5.webp',
                        type: 'Bok Bahri',
                        height: '503px'
                    },
                ],

                
                 // fib-alshingity
                 [
                    {
                        path : 'assets/images/portfolio/fib-alshingity/5.webp',
                        type: 'Fib Alshingity',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/fib-alshingity/2.webp',
                        type: 'Fib Alshingity',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/fib-alshingity/3.webp',
                        type: 'Fib Alshingity',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/fib-alshingity/4.webp',
                        type: 'Fib Alshingity',
                        height: '503px'
                    },
                    {
                        path : 'assets/images/portfolio/fib-alshingity/1.webp',
                        type: 'Fib Alshingity',
                        height: '503px'
                    },
                ],
                
                // gold-borsa
                [
                    {
                        path : 'assets/images/portfolio/gold-borsa/1.webp',
                        type: 'Gold Borsa',
                        height: '503px'
                    },
                    // {
                    //     path : 'assets/images/portfolio/gold-borsa/2.webp',
                    //     type: 'Gold Borsa',
                    //     height: '503px'
                    // },
 
                ],
                 
                
                

            ],
        }
    },
    methods: {
        preview(){
            var config = {}
            try {
                // console.log(this.previewConfig);
                // config = JSON.parse(this.previewConfig)
                config = JSON.parse(JSON.stringify(this.previewConfig))
                
            } catch (e) {
                console.log(e);
            }
            config.onClose = () => {
                console.log('preview has been closed')
            }
            // eslint-disable-next-line no-unused-vars
            const preview = imagePreview(config)
        },
        slideClicked( images = [] , event ) {
            console.log(event);
            
            if(
                !event.target.classList.contains('swiper-pagination-bullet') &&
                !event.target.classList.contains('swiper-button-next') &&
                !event.target.classList.contains('swiper-button-prev')
            ) {
                // let item = this.swiper.realIndex;
                // this.previewConfig.initIndex = item + 1
                var imagePath = this.getImageNameInArray(images)
                this.previewConfig.images = imagePath
                this.preview()
                //this.$router.push();
            }
      },

      changePreviewTap(first, last) {
        this.firstShowing = first
        this.lastShowing = last
      },
      getImageNameInArray(objArr) {
        const images = [] 
         objArr.filter(function(item) {
            images.push(item.path)
        });
        return images

      },
      handleClickSlide(index) {
           console.log ('current click index:', index);
       } 
    },
    computed: {
    swiper() {
      return this.$refs.swiper.swiper
    }
  },
}

</script>

<style>
    .portfolio-menu ul li{
        width: 200px !important;
        display: inline-flex;
        font-size: 16px;

        font-weight: 700;
        color: #6c6c6c;
        line-height: 48px;
        padding: 0 30px;
        /* position: relative; */
        z-index: 5;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        cursor: pointer;
        border-radius: 50px;
        overflow: hidden;
        margin-top: 4px;
        text-transform: uppercase;
    }


    @media(max-width :1680px) {
        .project-box {
            width: 1319px;
            
        }
    }

    @media(max-width :1200px) {
        .portfolio-menu ul li{
            width: 180px !important;
        }

        .project-box {
            width: 1319px;
            
        }
        .project-item {
            width: calc(33.333333% - 40px);
        }
    }
    @media(max-width :1000px) {
        .portfolio-menu ul li{
            width: 100% !important;
            font-size: 16px;
            display: block;
            text-align: center;
            font-weight: 700;
            color: #6c6c6c;
            line-height: 48px;
            padding: 0 30px;
            /* position: relative; */
            z-index: 5;
            -webkit-transition: all 0.3s ease-out 0s;
            -moz-transition: all 0.3s ease-out 0s;
            -ms-transition: all 0.3s ease-out 0s;
            -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;
            cursor: pointer;
            border-radius: 50px;
            overflow: hidden;
            margin-top: 4px;
            text-transform: uppercase;
        }

        .project-box {
            width: 1100px;
        }
        
    }


    @media screen and (max-width :1330px) { 
        .project-box {
            width: 1100px;
        }
    }


   @media screen and (max-width :1024px) { 
        .project-box {
            width: 977px;
        }
    }

   @media screen and (max-width :900px) { 
        .project-box {
            width: 747px;
        }
    }

   @media screen and (max-width :768px) { 
        .project-box {
            width: 747px;
        }
    }

   @media screen and (max-width :600px) { 
        .project-box {
            width: 549px;
        }
    }

   @media screen and (max-width :480px) { 
        .project-box {
            width: 367px;
        }

        .project-item {
            width: calc(50% - 40px) !important;
            
        }
        .swiper-container{
            height: 120px !important;
        }

        .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
            bottom: -7px !important;
        }

        .project-img {
            height: 80% !important;
        }

        .single-project .project-content .project-title {
            font-size: 14px;
        }
    }

   @media screen and (max-width :320px) { 
        .project-box {
            width: 317px;
        }
    }


    .portfolio-area{
        background-color: white;
    }


    .single-portfolio .portfolio-image img{
        /* height: 247px; */
        padding: 5px;
    }

  .img-height{
    height: 494px !important;
  }


  .swiper-container{
      height: 280px;
  }

    .swiper-pagination-bullet{
        width: 10px !important;
        height: 10px !important;
        display: inline-block;
        border-radius: 100%;
        background: #208e87 !important; 
        opacity: 0.8 !important;
        
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0px !important;
    }

    .swiper-pagination-bullet-active{
        background: white !important;

        width: 14px !important;
        height: 14px !important;
        top: 0px;
        left: 0px;
        background-color: #efefef;
        border: 1px solid #208e87;
    }

    .project-box {
        box-sizing: border-box;
        border: 1px solid #cccccc !important;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 1;

    }

    .project-item {
        float: left;
        width: calc(33.333333% - 40px);
        box-sizing: border-box;
        margin: 20px;
        position: relative;

    }
    .project-img {
        width: 100%;
        height: 90%;
    }

    /*pagination style*/
    .pagination {
        display: inline-block;
      }
      
      .pagination a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
      }
      
      .pagination a.active {
        background-color: #1f8e88;
        color: white;
        border-radius: 50%;
        border: 1px solid white;
      }
      
      .pagination a:hover:not(.active) {background-color: #ddd;}

      .image-preview__action-bar {
        display: none !important;
      }
</style>